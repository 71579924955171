import React, { useState, useEffect } from "react"
import { Cookies, getCookieConsentValue } from "react-cookie-consent"
import LaunchExperience from "../components/eLearning/launch-experience"
import { isBrowser, pageMapping, hideCookiePreference } from "../utils"
export const myContext = React.createContext()
const rxDefineId = process.env.GATSBY_RXDEFINEID

const Provider = ({ children }) => {
  const [headerHeight, setHeaderHeight] = useState(0)
  const [mobileMenu, setMobileMenu] = useState(false)
  const [shouldHideHeader, setShouldHideHeader] = useState(false)
  const [hvaStatus, setHvaStatus] = useState({ AXS_AUV_BHCP_W_VISIT: false })
  const [activeParent, setActiveParent] = useState("")
  const [navVisible, setNavVisible] = useState(true)
  const [firstLoad, setFirstLoad] = useState(false)
  const [headerPinned, setHeaderPinned] = useState(false)
  const [disableHeader, setDisableHeader] = useState(false)
  const [heroHeight, setHeroHeight] = useState(0)
  const [currentPage, setCurrentPage] = useState("")
  const [isMobile, setIsMobile] = useState(false)
  const [inAuvelityExp, setInAuvelityExp] = useState(false)

  // isi state management
  const [indicationOpen, setIndicationOpen] = useState(true)
  const [safetyOpen, setSafetyOpen] = useState(false)
  const [isiExpanded, setIsiExpanded] = useState(false)
  const [isISIInitial, setIsISIInitial] = useState(true)

  // const [elearningProgess, setElearningProgress] = useState(0)
  const descriptionMapping = {
    AXS_AUV_BHCP_W_VISIT: "HCP Site Visit",
    AXS_AUV_BHCP_W_SAMP: "Click to eSample vendor site",
    AXS_AUV_BHCP_W_RAR: "Request a Rep form completion",
    AXS_AUV_BHCP_W_PI: "PI download",
    AXS_AUV_BHCP_W_MG: "Med Guide download",
    AXS_AUV_BHCP_W_PA: "PA Support document download",
    AXS_AUV_BHCP_W_MEDINFO: "Request Medical Information",
    AXS_AUV_BHCP_W_SAF: "Visited Safety page",
    AXS_AUV_BHCP_W_DOS: "Visited Dosing data page",
    AXS_AUV_BHCP_W_EFF: "Visited Efficacy page",
    AXS_AUV_BHCP_W_MOA: "Visited MOA page",
    AXS_AUV_BHCP_W_MOD1: "Completed MOA Module",
    AXS_AUV_BHCP_W_MOD2: "Completed Efficacy - Symptom Relief Module",
    AXS_AUV_BHCP_W_MOD3: "Completed Efficacy - Remission Module",
    AXS_AUV_BHCP_W_MOD4: "Completed Active Control Module",
    AXS_AUV_BHCP_W_MOD5: "Completed 1 Year Data Module",
    AXS_AUV_BHCP_W_PHARM: "Visited Pharmacist Section",
  }

  useEffect(() => {
    hideCookiePreference()
    // check if in auvelity experience page
    if (window.location.pathname.includes("auvelity-experience")) {
      setInAuvelityExp(true)
    } else {
      setInAuvelityExp(false)
    }
  })
  // state to track HVAs
  const updateHVAStatus = content_id => {
    const Engagement_kcrm__c = Cookies.get("axs-hcp-engagement-id")

    // changes status if engagement ID is set
    if (Engagement_kcrm__c) {
      setHvaStatus(prevState => {
        return {
          ...prevState,
          [content_id]: true,
        }
      })
    }
  }

  const createHvaLineObject = (content_id, engagement_state) => {
    const Engagement_kcrm__c = Cookies.get("axs-hcp-engagement-id")

    const hvaData = {
      Engagement_kcrm__c,
      Content_Id_kcrm__c: content_id,
      Engagement_State_kcrm__c: engagement_state,
      Description_kcrm__c: descriptionMapping[content_id],
      env: process.env.GATSBY_ENV,
    }

    if (Engagement_kcrm__c) {
      return fetch(`${process.env.GATSBY_API_URL}/api/create-record`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: JSON.stringify(hvaData),
      })
        .then(response => response.json())
        .then(data => {
          if (data.message === "no account found") {
            return
          }
          updateHVAStatus(content_id, engagement_state)
        })
        .catch(error => {
          console.error("Error:", error)
        })
    }
  }

  const setEngagementId = async (unique_id_name, unique_id_value) => {
    if (getCookieConsentValue("axs-hcp")) {
      //check if the loaded call has been made
      if (hvaStatus && hvaStatus.AXS_AUV_BHCP_W_VISIT === false) {
        let userData = {}
        const user_npi = Cookies.get("axs-hcp-user-npi")
        const user_veeva =
          unique_id_name === "veeva_network_id" && unique_id_value
            ? unique_id_value
            : false

        //if no veeva or npi, return
        if (user_veeva || user_npi) {
          userData = {
            type: user_veeva ? "Account_Identifier_kcrm__c" : "NPI_kcrm__c",
            id: user_veeva ? user_veeva : user_npi,
            description: "HCP Site Visit",
            env: process.env.GATSBY_ENV || "dev",
          }
          // Cookies.set("auvelity_engagement_type", user_veeva ? "veeva" : "npi")
        } else {
          return
        }

        if (!Cookies.get("axs-hcp-engagement-id")) {
          //get the known account id
          return fetch(`${process.env.GATSBY_API_URL}/api/get-account-id`, {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: JSON.stringify(userData),
          })
            .then(response => response.json())
            .then(data => {
              if (data.message === "no account found") {
                return
              }

              Cookies.set("axs-hcp-engagement-id", data.message)
              // update and create Engagement object on initial site visit
              updateHVAStatus("AXS_AUV_BHCP_W_VISIT")

              // if currentPage exists in pageMapping, track the page view
              const pathname =
                isBrowser && window.location.pathname.replaceAll("/", "")

              pageMapping[pathname] &&
                createHvaLineObject(pageMapping[pathname], "Viewed")
            })
            .catch(error => {
              console.error("Error:", error)
            })
        }
      }
    }
  }

  return (
    <myContext.Provider
      value={{
        hvaStatus,
        setEngagementId,
        createHvaLineObject,
        activeParent,
        setActiveParent,
        navVisible,
        setNavVisible,
        mobileMenu,
        setMobileMenu,
        headerHeight,
        setHeaderHeight,
        shouldHideHeader,
        setShouldHideHeader,
        firstLoad,
        setFirstLoad,
        headerPinned,
        setHeaderPinned,
        disableHeader,
        setDisableHeader,
        heroHeight,
        setHeroHeight,
        currentPage,
        setCurrentPage,
        isMobile,
        setIsMobile,
        indicationOpen,
        setIndicationOpen,
        safetyOpen,
        setSafetyOpen,
        isiExpanded,
        setIsiExpanded,
        isISIInitial,
        setIsISIInitial,
        inAuvelityExp,
        setInAuvelityExp,
        rxDefineId,
      }}
    >
      <LaunchExperience />
      {children}
    </myContext.Provider>
  )
}

const Consumer = ({ element }) => <Provider>{element}</Provider>
export default Consumer
