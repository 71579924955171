import React from "react"
import tw from "twin.macro"
import { navigate } from "gatsby"

const setTransitionPosition = (coords, module, prevPath) => {
  const transitionElement = document.querySelector("#transition")

  transitionElement.style.top = `${coords.clientY}px`
  transitionElement.style.left = `${coords.clientX}px`
  transitionElement.style.right = "auto"
  transitionElement.style.width = `2.5rem`
  transitionElement.style.height = `2.5rem`
  transitionElement.style.borderRadius = "9999px"
  transitionElement.style.transformOrigin = "50% 50% 0"
  transitionElement.style.transform = "scale(70)"

  //4. fill page
  setTimeout(() => {
    transitionElement.style.top = 0
    transitionElement.style.left = "auto"
    transitionElement.style.right = 0
    transitionElement.style.width = "100%"
    transitionElement.style.height = "100%"
    transitionElement.style.borderRadius = 0
    transitionElement.style.transformOrigin = "right"
    transitionElement.style.transform = "scaleX(1)"
  }, 750)

  //5. load new page
  setTimeout(() => {
    if (module) {
      navigate(`/auvelity-experience#${module}`, {
        state: { prevPath },
      })
    } else {
      navigate("/auvelity-experience", {
        state: { prevPath: "/" },
      })
    }
  }, 1200)

  //7. remove wipe
  setTimeout(() => {
    transitionElement.style.transform = "scaleX(0)"
  }, 1500)
}

export const launchExperience = (e, module, prevPath) => {
  const { clientX, clientY } = e
  setTransitionPosition(
    { clientX: clientX, clientY: clientY },
    module,
    prevPath
  )
  window && window.sessionStorage.setItem("axs-hcp-launchexp", "true")
}

const LaunchExperience = ({ startTransition }) => {
  return (
    <>
      <div
        id="transition"
        css={[
          tw`fixed transition duration-700 z-50 top-0 rounded-full bg-blue`,
          startTransition && tw`scale-150`,
        ]}
      ></div>
    </>
  )
}
export default LaunchExperience
